import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import Fg1 from '../../images/e1.jpg'
import Head from "../../components/header"

const ExamplesPage = () => (
  <Layout>
    <Head pageTitle="Case Examples - GAZIRU, Inc." pageLang ="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>Case Examples</h4>
          <h5>Secondary distribution (APRE Co.,Ltd.)</h5>
          <p>"TAL Grading Report Issuance Service" was provided, which enables management by mapping the actual product to the grading report in the secondary distribution of luxury brand goods.</p>

          <h6>Business Issues and Needs</h6>
          <p>In the secondary distribution of luxury brand goods, it is important to determine the authenticity and quality of the goods (hereinafter referred to as "authenticity determination"), which is performed by experts with special knowledge. However, there are few transactions that come with appraisal certificates, and each transaction requires a separate appraisal. However, there are few transactions that come with a certificate of authenticity, and each transaction requires a separate certificate of authenticity, making the number of experts needed for each transaction insufficient.</p>
          <p>Under these circumstances, our clients considered offering new services that would improve the efficiency of their authenticity determination operations and take advantage of our advanced authenticity determination technology.</p>

          <h6>Solution</h6>
          <p>Clients evaluate the condition of luxury brand goods that meet their own authenticity standards in terms of rank and quantitative values, and issue a grading report that visualizes the results. By utilizing the GAZIRU Z service, our clients have realized the "TAL Grading Report Issuance Service," which enables management by associating grading reports with images of products.</p><br />
          <img src={Fg1}></img><br />

          <br />

          <h5>Measures against counterfeit goods (DADWAY, INC.)</h5>
          <p>Ergobaby Baby's Carrier (new Japan-only product) has been adopted as an anti-counterfeiting measure.</p>
          <p>DADWAY, Inc. is the exclusive Japanese distributor of Ergobaby, a major U.S. baby product manufacturer. DADWAY has launched a baby carrier with new features developed jointly with Ergobaby, which is available only in Japan. GAZIRU service is used as one of the measures against counterfeit products of the same product.</p>

          <br />

          <h5>Other Customers/Industries</h5>
          <p>We developed a mechanism to load product images from department store catalogs and direct them to an EC site.</p>
          <h6>Electronic components, imaging systems, industrial functional components, fastenings, automobiles, materials, technical trading companies, etc.</h6>
          <p>
          </p>

          <br />

          <h5>Quotations and Inquiries</h5>
             <p>GAZIRU fees vary depending on the type of service and features used.</p>
             <p>Please feel free to contact us using the form below so that we can provide you with the best features and pricing for your needs.</p>

             <p><Link className="text-decoration-none" to="/notification/contact/en">Contact form</Link></p>

            <br />
            <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default ExamplesPage
